import { set, toggle } from '@/utils/vuex'
import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import router from './router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: null,
    login_in_status: false,
    text: null,
    color: null,
    alert: false,
    token: localStorage.getItem('user-token') || '',
    user_type: localStorage.getItem('user-type') || null,
    timer_begun: localStorage.getItem('timer-status') || false,
    date_start: localStorage.getItem('date-start') || null,
    bEditAuto: false,
  },
  mutations: {
    toggleEditAutomatic (state, value) {
      state.bEditAuto = value
    },
    toggle_alert (state, alert) {
      state.text = alert.text
      state.color = alert.color
      state.alert = true
    },
    toggle_auth (state, value) {
      state.login_in_status = value
    },
    toggle_type (state, value) {
      state.user_type = value
    },
    set_alert (state, value) {
      state.alert = value
    },
    auth_error (err) {
      localStorage.removeItem('user-token')
      localStorage.removeItem('user-type')
    },
    auth_success (state, response) {
      localStorage.setItem('user-token', response.token)
      localStorage.setItem('user-type', response.user.iRole)
      state.token = response.token
    },
  },
  actions: {
    login({commit}, user) {
      const uri = 'https://api.pemcorp.app' // 'http://ec2-52-91-114-127.compute-1.amazonaws.com'
      return new Promise((resolve, reject) => {
        var body = new URLSearchParams()
        body.append('sEmail', user.sEmail)
        body.append('sPassword', user.sPassword)
        Axios.post(`${uri}/v2/api/auth`, body)
        .then(resp => {
          commit('auth_success', resp.data)
          commit('toggle_auth', true)
          if (resp.data.user.iRole === 0) {
            router.push('/admin/outages')
            router.go()
          } else {
            router.push('/operator/outages')
            router.go()
          }
          resolve(resp)
        })
        .catch(err => {
          localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
          commit('auth_error', err);
          reject(err)
        })
      }) 
    },
    logout ({commit}) {
      return new Promise((resolve, reject) => {
        try {
          localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
          localStorage.removeItem('user-type') // if the request fails, remove any possible user token if possible
          localStorage.removeItem('user-session')
          localStorage.removeItem('OutageEdit')
          localStorage.removeItem('timer-status')
          localStorage.removeItem('date-start')
          commit('toggle_auth', false)
          resolve({color: 'success', text: 'Sesión terminada'})
        } catch (e) {
          reject({color: 'fail',  text: 'Hubo un error terminando la sesíon, favor de intentar de nuevo.'})
        }
      }) 
    }
  }
})
