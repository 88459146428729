const routes = [
  {
    path: '/operator',
    name: 'Operator',
    component: () => import('@/views/Operator'),
    meta: { 
      requiresAuth: true,
      userType: 1
    },
    children: [
      {
        path: 'outages',
        name: 'Outages',
        component: () => import('@/views/operator/Outages'),
        meta: { 
          requiresAuth: true,
          userType: 1
        }
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('@/views/operator/Notifications'),
        meta: { 
          requiresAuth: true,
          userType: 1
        }
      },
      {
        path: '*',
        component: () => import('@/views/operator/Outages'),
      }
    ],
  }
]

export default routes