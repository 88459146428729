import Vue from 'vue'
import Router from 'vue-router'

// importing VueX state management for verifying auth 
import store from './store'
// import file that manages non-auth user routes
import router_operator from '@/router_operator'
// import file that manages auth user routes
import router_admin from '@/router_admin'
// import file that manages auth user routes
import router_guest from '@/router_guest'

Vue.use(Router)

// merge route files to a single component to inititate the vue router instance
var allRoutes = []
allRoutes = allRoutes.concat(router_operator, router_admin, router_guest)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: allRoutes
})

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(require('vue-analytics').default, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development'
    }
  })
}

// initiate authentication filter for private routes
// Type of user logged in
// store.state.userType 
router.beforeEach((to, from, next) => {
  // if requieres log in
  if (to.meta.requiresAuth) {
    // check if user logged in
    if (store.state.login_in_status) {
      // if logged in check if it is the correct user
      if (to.meta.userRole == store.state.role) {
        // allow next if it matches
        next()
      } else {
        // return to user's home if it does it match
        next('/')
      }
    } else {
      // if not return to user's hoome
      next('/')
    }
  } else {
    // if not allow next
    next()
  }
})

export default router
