const routes = [
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/Admin'),
    meta: {
      requiresAuth: true,
      userType: 0
    },
    children: [
      {
        path: 'reports',
        name: 'Reports',
        component: () => import('@/views/admin/Reports'),
        meta: { 
          requiresAuth: true,
          userType: 0
        }
      },
      {
        path: 'clients',
        name: 'Clients',
        component: () => import('@/views/admin/Clients'),
        meta: { 
          requiresAuth: true,
          userType: 0
        }
      },
      {
        path: 'operators',
        name: 'Operators',
        component: () => import('@/views/admin/Operators'),
        meta: { 
          requiresAuth: true,
          userType: 0
        }
      },
      {
        path: 'outages',
        name: 'Outages',
        component: () => import('@/views/admin/Outages'),
        meta: { 
          requiresAuth: true,
          userType: 0
        }
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('@/views/admin/Notifications'),
        meta: { 
          requiresAuth: true,
          userType: 0
        }
      },
      {
        path: '*',
        name: 'Error',
        component: () => import('@/views/Error'),
        meta: { 
          requiresAuth: true,
          userType: 0
        }
      },
      {
        path: '*',
        component: () => import('@/views/admin/Outages'),
      }
    ]
  }
]

export default routes