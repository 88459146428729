const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home')
  },
  {
    path: '*',
    component: () => import('@/views/Home')
  }
]

export default routes